import { Fragment } from "react";
import { ARTICLE_CHANNELS, RESOURCE_LABELS, RESOURCE_TYPES, ROLES } from "../../util/constants"
import Parents from "../components/Resources/Tabs/Parents";
import Staff from "../components/Resources/Tabs/Staff";
import StudentResourceTab from "../components/Resources/Tabs/Student";
import { APPS } from "./appWidgets";
import OHIOIcon from "../../Assets/Images/OHIO";

export const UNAUTH_CONSTANT = "UNAUTH"

let unauthSections = [
    {
        tab: 0,
        resourceType: RESOURCE_TYPES.STUDENTS,
        label: RESOURCE_LABELS[RESOURCE_TYPES.STUDENTS],
        app: APPS.OHIO_STATE_APP,
        newsChannel: ARTICLE_CHANNELS[ROLES.STUDENT],
        Component: StudentResourceTab
    },
    {
        tab: 1,
        resourceType: RESOURCE_TYPES.STAFF_FAC,
        label: RESOURCE_LABELS[RESOURCE_TYPES.STAFF_FAC],
        app: APPS.OHIO_STATE_APP,
        newsChannel: ARTICLE_CHANNELS[ROLES.EMPLOYEE],
        Component: Staff
    },
    {
        tab: 2,
        resourceType: RESOURCE_TYPES.PARENTS_FAM,
        label: RESOURCE_LABELS[RESOURCE_TYPES.PARENTS_FAM],
        app: APPS.OHIO_STATE_APP,
        Component: Parents
    }
]

unauthSections.forEach(section => section.id = UNAUTH_CONSTANT + "-" + section.resourceType)

const UNAUTH = {
    type: UNAUTH_CONSTANT,
    heading: <Fragment>
        Welcome to Buckeye Link
        <OHIOIcon
            sx={(theme) => {
                const color = theme.palette.brand.gray.main
                
                return {
                    ml: "auto",
                    fontSize: "inherit",
                    fill:color,
                    color
                }
            }}
        />
    </Fragment>,
    sections: unauthSections
}

export {
    UNAUTH
}