import { groupedSections, sections } from "../../Dashboard/util/accordionSections"
import { compareUserRolesToAllowedRoles } from "../../util/functions"
import { getGoogle, getPerson, searchAcrossContentServer } from "../data-sources/enums"
import { searchOptions } from "./constants"
import { searchQueries } from "./queries"
import { filterLinksWithRoles } from "./slices/buckeye-link"


const _createQuery = (gqlOptions, gqlQueries, queries) => {
    let variables = `$query: String`
    const required= queries.includes(getGoogle) || queries.includes(searchAcrossContentServer)
    if(required) {
        variables += '!'
    }
    if(queries.includes(getGoogle)) variables += `, $startIndex: String`
    if(queries.includes(getPerson)) variables += `, $nextPageLink: String`
    let query = `query ${gqlOptions.GET} (${variables}) {`

    queries.forEach((q) => {
        if(gqlQueries[q]) {
            query += gqlQueries[q]
        }
    })

    query += `
        }
    `

    return query
}

export function formatQuickLinks({ links = [], roles = [] }) {
    let modifiedQuickLinks = links
    if(roles?.length) {
        const linksFilteredByRole = filterLinksWithRoles(modifiedQuickLinks, roles)
        if(linksFilteredByRole?.length) {
            modifiedQuickLinks = linksFilteredByRole
        } 
    } else {
        const unauthLinks = modifiedQuickLinks.filter(o => !o?.roles?.length || o.roles.includes('UNAUTH'))
        if(unauthLinks?.length) {
            modifiedQuickLinks = unauthLinks
        }
    }

    return modifiedQuickLinks
}

function createSearchQuery(queries = []) {
    return _createQuery(searchOptions, searchQueries, queries)
}

export const determineDashboardQueriesAppropriateForUser = (viewKey = '', groupIdFilter = '', userAuthRoles = []) => {
    const groupFilteredSections = groupedSections[viewKey] ?? []
    const sectionQueries = []
    groupFilteredSections.forEach(({ id, query: groupQuery, sections: groupSections = [] }) => {
        const sectionMatchesFilterId = id === groupIdFilter
        if(groupQuery && groupIdFilter && sectionMatchesFilterId) {
            sectionQueries.push(groupQuery)
        }
        groupSections.forEach((sectionKey) => {
        const match = sections.find(o => o.key === sectionKey) ?? {}
        const { query, roles } = match
        const roleEligible = !roles?.length || compareUserRolesToAllowedRoles(userAuthRoles, roles)
        let canSeeSection = query &&  roleEligible
        if(groupIdFilter && canSeeSection) {
          canSeeSection = sectionMatchesFilterId
        }

        if(canSeeSection) {
          sectionQueries.push(query)
        }
      })
    })

    return sectionQueries
}
  

export {
    createSearchQuery
}
