import { ARTICLE_CHANNELS, ROLE_CONDITIONS, ROLES } from "../../../util/constants";
import { compareUserRolesToAllowedRoles } from "../../../util/functions";
import { setRoles } from "../../authorization/slices";
import { updateBuckeyeLinkData } from "../../graphql/slices/buckeye-link";
import { setChannels } from "../../graphql/slices/content";
import { setRoleType } from "../../graphql/slices/dashboard";
import { setStandardHours } from "../../graphql/slices/hrProfileData";
import { getAffiliations } from "../enums";

const CONSTANTS = {
  get: getAffiliations,
};

const get = `
    ${CONSTANTS.get} {
      affiliations
      standardHours
    }
`;

const formatChannels = (roles = []) => {
  const isStaffFaculty = compareUserRolesToAllowedRoles(roles, ROLE_CONDITIONS.staffFaculty)
  const isAlumni = compareUserRolesToAllowedRoles(roles, ROLE_CONDITIONS.alumni)

  let channels = []
  if(isStaffFaculty) {
    channels.push(ARTICLE_CHANNELS[ROLES.EMPLOYEE])
  }
  if(isAlumni) {
    channels.push(ARTICLE_CHANNELS[ROLES.ALUMNI])
  }
  return channels
}

const transformResponse = (response = {}, { dispatch }) => {
  const getAff = response?.data?.[getAffiliations]
  const roles = getAff?.affiliations ?? []
  if(roles?.length) {
    dispatch(setRoles({ roles }))
    dispatch(
      updateBuckeyeLinkData({
        roles
      })
    );
    dispatch(
      setRoleType(roles)
    );
    const customChannels = formatChannels(roles)
    if(customChannels?.length) {
      dispatch(setChannels({ channels: customChannels }))
    }
  }
  if(getAff?.standardHours) {
    dispatch(setStandardHours(getAff.standardHours))
  }
  return {
    data: {
      //Keeping this to the original `getAffiliations` string for endpoint versioning reasoning. If using useGetAffiliationsQuery, make sure to reference the return data as just getAffiliations
      getAffiliations: roles
    }
  }
}


const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
  transformResponse
};
  

export default source;
